import { FormModel_scopeItems, mapItemScopeFromBE, mapItemScopeToBE } from '@prisma/containers/model/scope.models';
import { _isDev, _isFeDev, _log, _warn } from '@shared/aux_helper_environment';
import { _array2plainId, _cloneDeep, _get, _pick, _reset_getNewNumberId } from '@shared/aux_helper_functions';
import {
  DEFAULT_INTERNAL_CODE_PROP,
  DEFAULT_INTERNAL_UOM_PROP,
  _auxReduceProdsStoresInfo,
  _clonePlanogram,
  _round,
  _set_internalItemId,
} from './spaces_aux_helpers';

const SHELF_ID_PREFIX = '_SH-'; //Se concatena este prefijo para evitar colisiones
export const MOD_ID_PREFIX = '_MOD-'; //Se concatena este prefijo para evitar colisiones
const PROD_ID_PREFIX = '_PRD-'; //Se concatena este prefijo para evitar colisiones
const PROD_POP_ID_PREFIX = '_PRDPOP-'; //Se concatena este prefijo para evitar colisiones
const FLOAT_ID_PREFIX = '_FLOAT-'; //Se concatena este prefijo para evitar colisiones
const TEST_PRICE_ZERO = false && _isDev();

/*
    Trasnformaciones para load
    INPUT
*/

const _addInternalObjId = (_obj, _ID) => {
  if (_obj?.id == null) return;
  _obj.id = _ID + String(_obj.id).replace(_ID, '');
};

//DES-2097 / Codigo para imresión de planogramas
const _addInternalCode = _objMetadata => {
  if (_objMetadata == null) return;

  let key = DEFAULT_INTERNAL_CODE_PROP;
  let keyUom = DEFAULT_INTERNAL_UOM_PROP;

  let _internalCode = _objMetadata?.[key]; // _obj?.code ?? _obj?.ean;
  let _internalUom = _objMetadata?.[keyUom];
  if (false) _log('[_addInternalCode]', key, _objMetadata?.[key], _internalCode, _internalUom);

  if (_isFeDev() && _internalCode && false) {
    _internalCode = 'ICode:' + _internalCode;
  }

  if (_internalCode != null || _internalUom != null) {
    _objMetadata._internalCode = _internalCode;
    _objMetadata._internalCodeUom = _internalCode + '-' + _internalUom;
  } else {
    console.warn('_auxTransformOutputData_prodTransform: No se puede agregar el codigo interno porque no existe el codigo', _objMetadata);
  }

  //CAT-19
  let isEmptySpace = _objMetadata?.code === 'EmptySpace';
  if (isEmptySpace) {
    _objMetadata.name = 'Espacio vacío';
    _objMetadata.canRotate = false;
    _objMetadata.canRotateY = false;
  }
  _objMetadata.isEmptySpace = isEmptySpace;
};

export const _removeInternalObjId = (_obj, _ID) => {
  if (_obj?.id == null) return;
  if (String(_obj.id).includes(_ID)) _obj.id = _obj.id.replace(_ID, '');
  if (_obj.id == Number(_obj.id)) _obj.id = Number(_obj.id);
  return _obj.id;
};

export const _auxTransformInputPlanogramData = ($planogram, featureFlagScope) => {
  let obj = _cloneDeep($planogram);

  obj.modules = (obj.modules || [])
    .sort((x, y) => {
      if (x.order == null || x.order === y.order) return 0;
      return x.order < y.order ? -1 : 1;
    })
    .map(mod => {
      mod.shelfs = (mod.shelfs || [])
        .sort((x, y) => {
          if (x.order == null || x.order === y.order) return 0;
          return x.order < y.order ? -1 : 1;
        })
        .map(shelf => {
          shelf.prods = (shelf.prods || [])
            .filter(p => !!(p && p.itemData && p.itemData.itemId && p.itemData.size && p.itemData.size.w))
            .sort((x, y) => {
              if (x.order == null || x.order === y.order) return 0;
              return x.order < y.order ? -1 : 1;
            })
            .map($prod => {
              let prod = _cloneDeep($prod);

              prod.itemData._internalItemId = _set_internalItemId(prod.itemData.itemId, prod.itemData.unitOfMeasureId);

              if (prod.itemData.size) prod.itemData.size.d = prod.itemData.size.d || prod.itemData.size.w;
              if (prod.itemData.canRotate != undefined) prod.itemData.cantRotate = !prod.itemData.canRotate;
              prod.itemData.canRotateY = prod.itemData.canRotateY != null ? prod.itemData.canRotateY : true;

              if (shelf.type === 'normalShelf-level') delete prod.position;

              delete prod.size;
              delete prod.width;
              delete prod.height;

              //2v5gkb / para testear con precio 0
              if (_isDev() && TEST_PRICE_ZERO) {
                prod.itemData.storesInfo = prod.itemData.storesInfo.map(el => {
                  return { ...el, price: 0 };
                });
              }

              //DES-5001
              if (false && _isDev()) {
                prod.itemData.ean = null;
              }

              _addInternalObjId(prod, PROD_ID_PREFIX);
              _addInternalCode(prod?.itemData);

              //DES-1501
              if (!prod.facings || prod.facings < 1) prod.facings = 1;

              //CAT-19
              let isEmptySpace = prod.itemData?.isEmptySpace;

              return _auxTransformInputData_genericTransform(prod);
            });

          if (shelf.type !== 'pitShelf-level') delete shelf.depth;
          delete shelf.position;
          delete shelf.width;

          //DES-1576
          shelf.marginLeft = shelf.marginLeft || 0;
          shelf.marginRight = shelf.marginRight || 0;

          _addInternalObjId(shelf, SHELF_ID_PREFIX);
          return _auxTransformInputData_genericTransform(shelf);
        });

      delete mod.position;
      delete mod.doorType;
      delete mod.height;

      _addInternalObjId(mod, MOD_ID_PREFIX);
      return _auxTransformInputData_genericTransform(mod);
    });

  obj.floats = (obj.floats || []).map((float, $i) => {
    if (float) {
      let i = $i + 1;

      float.prods = (
        float.prods?.length > 0
          ? float.prods
          : [
              {
                itemId: i,
                unitOfMeasureId: i,
                offsetY: 0,
                itemData: {
                  itemId: i,
                  unitOfMeasureId: i,
                  imagePlanogramFront: float.imageUrl,
                  width: float.width,
                  height: float.height,
                  size: {
                    w: float.width,
                    h: float.height,
                    d: 10,
                  },
                },
              },
            ]
      )
        .filter(p => !!(p && p.itemData && p.itemData.itemId && p.itemData.size && p.itemData.size.w))
        .map(prod => {
          prod.itemData._internalItemId = _set_internalItemId(prod.itemData.itemId, prod.itemData.unitOfMeasureId);
          _addInternalObjId(prod, PROD_POP_ID_PREFIX);
          _addInternalCode(prod?.itemData);
          return _auxTransformInputData_genericTransform(prod);
        });
    }

    _addInternalObjId(float, FLOAT_ID_PREFIX);
    return _auxTransformInputData_genericTransform(float);
  });

  obj.publishedDate = new Date(obj.publishedDate || obj.publishDate || new Date());

  obj.description = obj.planogramDescription || '';
  delete obj.planogramDescription;

  obj.planogramType = 'Planogram';

  if ($planogram?.structureOnly) obj.planogramType = 'Structure';
  if ($planogram?.isGenericSpace) obj.planogramType = 'GenericSpace';

  if (featureFlagScope) {
    const itemscopeFromBe: FormModel_scopeItems = _pick(
      obj?.itemScope || {
        itemIds: [],
        selectionType: 1,
        genericCategorysSelection: {
          categorys: [],
          categorysExcluded: [],
          subCategorys: [],
          subCategorysExcluded: [],
        },
        tagValueIdsToInclude: [],
        tagValueIdsToExclude: [],
        onlyItemsAllowedForSelling: true,
      },
      ScopeItems_KEYS
    );
    const itemScope = mapItemScopeFromBE(itemscopeFromBe);
    obj.itemScope = itemScope;
  }

  obj.promotionMaterial = $planogram?.promotionMaterial || [];
  obj.imageUrl = $planogram?.imageUrl || '';
  obj.width = $planogram?.width || null;
  obj.height = $planogram?.height || null;
  obj.depth = $planogram?.depth || null;

  return _auxTransformInputData_genericTransform(obj);
};

export const _auxTransValidTimeLine = $planograms => {
  if (!$planograms) return [];

  const CHECK_DIFF_DAY = true;

  let _lastDate = null;
  const rv = $planograms.filter(pog => {
    let isOk = pog && pog.modules && pog.modules.length && pog.publishDate;

    let date = new Date(pog.publishDate);
    if (CHECK_DIFF_DAY && isOk && _lastDate) {
      let diffDays = Math.abs(Math.ceil(date.getTime() - _lastDate.getTime()) / (1000 * 60 * 60 * 24));
      if (diffDays < 1) return false;
    }
    if (isOk) _lastDate = date;

    if (true && !isOk) console.error('[timeline whitout modules]', pog);
    return isOk;
  });

  return rv;
};

export const _auxTransformInputListProdsData = data => {
  data.items
    ? (data.items = data.items.map(i => {
        _addInternalCode(i);

        return {
          ...i,
          size: i.size
            ? {
                w: _round(i.size.width || i.size.w),
                h: _round(i.size.height || i.size.h),
                d: _round(i.size.depth || i.size.d || i.size.width || i.size.w),
              }
            : { w: 0, h: 0, d: 0 },
        };
      }))
    : data;

  data.items = _auxReduceProdsStoresInfo(data.items) || [];

  if (_isDev() && TEST_PRICE_ZERO) {
    data.items = data.items.map(i => {
      i.storesInfo = (i.storesInfo || []).map(el => {
        return { ...el, price: 0 };
      });
      if (i.price) i.price = 0;
      return i;
    });
  }

  if (data.totalItemCount != undefined) data.totalItems = data.totalItemCount || 0;

  return data;
};

export const _auxTransformInputListPopData = $data => {
  let data = _cloneDeep($data);

  if (data?.items?.length > 0) {
    data.items = (data.items || []).map(items => {
      items.prods = (items?.prods || []).map(prod => {
        if (prod.itemData) _addInternalCode(prod.itemData);
        return prod;
      });
      return items;
    });
  }

  return data;
};

const _auxTransformInputData_genericTransform = _obj => {
  Object.entries(_obj).forEach(([key, value]) => {
    //Borra las props que comienzan con $
    if (key[0] === '$') {
      delete _obj[key];
    }

    //Borra las props que son dataCalculada
    if (key.indexOf('CALC_') === 0 || key.indexOf('calc_') === 0) {
      delete _obj[key];
    }

    if (false) delete _obj.order;
  });
  return _obj;
};

/*
    Trasnformaciones para save
    OUTPUT
*/
export const _auxTransformOutputPlanogramData = ($planogram, calcDataMod, featureFlagScope = false) => {
  let planogram = _clonePlanogram($planogram);

  delete planogram._viewType;
  delete planogram.stageSize;
  delete planogram.state;
  delete planogram.publishedDate;
  delete planogram.publishDate;
  delete planogram.status;

  planogram.planogramDescription = planogram.description || '';
  delete planogram.description;

  if (!planogram.id) planogram.id = 0;

  const isStructure = planogram.planogramType === 'Structure';
  const isGenericSpace = planogram.planogramType === 'GenericSpace';

  planogram.structureOnly = isStructure;
  planogram.isGenericSpace = isGenericSpace;

  delete planogram.planogramType;

  if (calcDataMod) {
    if (false) _log('\n[calcDataMod]', calcDataMod, '\n');
    //DES-1451 Redondeo de cálculos
    planogram.CALC_totalLineSpace = Math.round(calcDataMod.shelfs_totalLineSpace || 0);
    planogram.CALC_totalItems = Math.round(calcDataMod.totalItems || 0);
    planogram.CALC_totalFacings = Math.round(calcDataMod.totalFacings || 0);
  } else {
    _warn('NO calcDataMod', calcDataMod);
  }

  if (!featureFlagScope) {
    planogram.categoriesSelected = _array2plainId(planogram.categoriesSelected);
    if (isStructure) planogram.categoriesSelected = [];
  } else {
    //TODO para cuando se quite el feature flag hay que eliminar esta prop de modelo store html del general
    //    🔥 Ojo: Hay que definir con BE como se va a cambiar este tema pq si esta prop no viaja, así como esta hoy pincha
    //            esto fue lo que produjo hacer el rollBack del FF DES-2998 y hacer el fix CAT-588
    //            hay un enforzado en el servicio, ver: id:z_cPCzaDNc
    delete planogram.categoriesSelected;
  }

  planogram.storesSelected = _array2plainId(planogram.storesSelected);

  planogram.floats = isStructure
    ? []
    : (planogram.floats || []).map(float => {
        delete float.prods;
        delete float.name;
        delete float.image;

        float.idFloatRef = float.idFloatRef;

        _removeInternalObjId(float, FLOAT_ID_PREFIX);
        return _auxTransformOutputData_genericTransform(float);
      });

  planogram.modules = (planogram.modules || []).map(mod => {
    mod = _auxTransformOutputData_modTransform(mod);
    mod.shelfs = (mod.shelfs || []).map(shelf => {
      shelf = _auxTransformOutputData_shelfTransform(shelf);
      shelf.prods = isStructure
        ? []
        : (shelf.prods || []).map(prod => {
            prod = _auxTransformOutputData_prodTransform(prod, shelf.type);
            return prod;
          });

      return shelf;
    });
    return mod;
  });
  if (featureFlagScope) {
    const $itemScope = mapItemScopeToBE(planogram, true, undefined, true);
    let itemScope: FormModel_scopeItems = _pick($itemScope || {}, ScopeItems_KEYS);
    itemScope.onlyItemsAllowedForSelling = true;
    planogram.itemScope = itemScope;
  }

  planogram.constraints = _reset_getNewNumberId(planogram.constraints, 'id');
  if (isStructure) delete planogram.constraints;

  planogram.imageUrl = $planogram.imageUrl;

  return _auxTransformOutputData_genericTransform(planogram);
};

const _auxTransformOutputData_genericTransform = _obj => {
  Object.entries(_obj).forEach(([key, value]) => {
    //Borra las props que comienzan con underscore

    //Solo se guardan los CALC_ no calc_
    if (key.indexOf('calc_') === 0) {
      delete _obj[key];
    }

    //Tranforma el prefix de $
    if (key[0] === '$') {
      _obj['CALC_' + key.substring(1)] = _cloneDeep(value);
      delete _obj[key];
    }

    if (key[0] === '_') {
      delete _obj[key];
    }

    if (key.indexOf('$parenShelf') !== -1) {
      delete _obj['CALC_' + key.substring(1)];
      delete _obj[key];
    }

    //Resetea IDs internos
    if ((key === 'id' || key === 'idFloatRef') && typeof value === 'string') {
      _obj[key] = 0;
    }

    //Resetea si es id negativo
    if ((key === 'id' || key === 'idFloatRef') && String(value)[0] === '-') {
      _obj[key] = 0;
    }
  });

  return _obj;
};

const _auxTransformOutputData_modTransform = _mod => {
  if (!_mod.id) _mod.id = 0;

  if (_mod.boxData) {
    delete _mod.boxData.position;
    delete _mod.boxData.size;
  }

  delete _mod.bottomData;
  delete _mod.offsetBox;
  delete _mod.offsetShelf;

  _removeInternalObjId(_mod, MOD_ID_PREFIX);
  return _auxTransformOutputData_genericTransform(_mod);
};

const _auxTransformOutputData_shelfTransform = _shelf => {
  //Si tiene prefijo se lo saca

  if (!_shelf.id) _shelf.id = 0;

  delete _shelf.width;
  delete _shelf.position;

  //DES-1576
  _shelf.marginLeft = _shelf.marginLeft || 0;
  _shelf.marginRight = _shelf.marginRight || 0;

  if (_shelf.type !== 'pitShelf-level') delete _shelf.depth;

  _removeInternalObjId(_shelf, SHELF_ID_PREFIX);
  return _auxTransformOutputData_genericTransform(_shelf);
};

export const _auxTransformOutputData_prodTransform = (_prod, _shelType) => {
  if (!_prod.id) _prod.id = 0;

  if (_shelType === 'normalShelf-level') {
    delete _prod.position;
  }

  _prod.itemId = _prod.itemData.itemId;
  _prod.unitOfMeasureId = _prod.itemData.unitOfMeasureId;

  //Defaults
  _prod.rotation = _prod.rotation || 0;
  _prod.stacksLyingDown = _prod.stacksLyingDown || false;

  //DataCalc
  _prod.$stacks = _prod.stacks || 1;
  _prod.$totalWidth = _prod.$totalWidth || _get(_prod, 'size.w') || 0;

  if (_shelType === 'float-pop-impulse-strip-simple') {
    delete _prod.id;
    delete _prod.$stacks;
    delete _prod.$totalWidth;
    delete _prod.rotation;
    delete _prod.stacksLyingDown;
  }

  //Delete
  delete _prod.itemData;
  delete _prod.stacks;
  delete _prod.groupImgItems;
  delete _prod.size;
  delete _prod.imgSize;
  delete _prod.isList;
  delete _prod._internalItemId;

  _removeInternalObjId(_prod, PROD_ID_PREFIX);
  _removeInternalObjId(_prod, PROD_POP_ID_PREFIX);

  //DES-1501
  if (!_prod.facings || _prod.facings < 1) _prod.facings = 1;

  return _auxTransformOutputData_genericTransform(_cloneDeep(_prod));
};

const _ScopeItems_KEYS: FormModel_scopeItems | any = {
  itemIds: null,
  categoryIdsToInclude: null,
  categoryIdsToExclude: null,
  tagValueIdsToInclude: null,
  tagValueIdsToExclude: null,
  itemIdsToExclude: null,
  onlyItemsAllowedForSelling: null,
};
const ScopeItems_KEYS = Object.keys(_ScopeItems_KEYS);
