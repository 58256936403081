import { FormModel_promotionCombos_FE } from './actionsV2-FormStateModel';

export const combos: FormModel_promotionCombos_FE[] = [
  {
    combo: [
      { items: [101, 102, 103, 104], stock: 12, name: 'Cerveza' },
      { items: [201, 202, 203, 204], stock: 6, name: 'Papas fritas' },
    ],

    id: '1',
    price: 100,
  },
  {
    combo: [
      { items: [101, 102, 103, 104], stock: 2, name: 'Coca cola' },
      { items: [201, 202, 203, 204], stock: 18, name: 'Hielo' },
      { items: [201, 202, 203, 204], stock: 18, name: 'Papas fritas' },
    ],
    id: '2',
    price: 100,
  },
  {
    combo: [
      { items: [301, 302, 303, 304], stock: 8, name: 'Hielo' },
      { items: [401, 402, 403, 404], stock: 10, name: 'Coca cola' },
    ],

    id: '3',
    price: 100,
  },
  {
    combo: [
      { items: [301, 302, 303, 304], stock: 8, name: 'Hielo' },
      { items: [401, 402, 403, 404], stock: 10, name: 'Coca cola' },
    ],

    id: '4',
    price: 100,
  },
  {
    combo: [
      { items: [301, 302, 303, 304], stock: 8, name: 'Hielo' },
      { items: [401, 402, 403, 404], stock: 10, name: 'Coca cola' },
    ],

    id: '5',
    price: 100,
  },
  {
    combo: [
      { items: [301, 302, 303, 304], stock: 8, name: 'Hielo' },
      { items: [401, 402, 403, 404], stock: 10, name: 'Coca cola' },
    ],

    id: '6',
    price: 100,
  },
  {
    combo: [
      { items: [301, 302, 303, 304], stock: 8, name: 'Hielo' },
      { items: [401, 402, 403, 404], stock: 10, name: 'Coca cola' },
    ],

    id: '7',
    price: 100,
  },
  {
    combo: [
      { items: [301, 302, 303, 304], stock: 8, name: 'Hielo' },
      { items: [401, 402, 403, 404], stock: 10, name: 'Coca cola' },
    ],

    id: '8',
    price: 100,
  },
];

export const promotionCombosDummyData = [
  {
    block: [
      {
        items: [1, 2, 3],
        stock: 10,
        name: 'Block 1',
      },
      {
        items: [4, 5],
        stock: 5,
        name: 'Block 2',
      },
    ],
    IsStaggered: true,
    value: 20,
    id: 10,
  },
  {
    block: [
      {
        items: [1, 2, 3],
        stock: 8,
        name: 'Block 1',
      },
      {
        items: [4, 5],
        stock: 4,
        name: 'Block 2',
      },
    ],
    IsStaggered: true,
    value: 15,
    id: 20,
  },
  {
    block: [
      {
        items: [1, 2, 3],
        stock: 7,
        name: 'Block 1',
      },
      {
        items: [4, 5],
        stock: 6,
        name: 'Block 2',
      },
    ],
    IsStaggered: true,
    value: 25,
    id: 30,
  },
  {
    block: [
      {
        items: [6, 7],
        stock: 8,
        name: 'Block 3',
      },
      {
        items: [8],
        stock: 3,
        name: 'Block 4',
      },
    ],
    IsStaggered: false,
    value: 0,
    id: 40,
  },
  {
    block: [
      {
        items: [9, 10],
        stock: 3,
        name: 'Block 5',
      },
      {
        items: [11, 12],
        stock: 7,
        name: 'Block 6',
      },
    ],
    IsStaggered: false,
    value: 22,
    id: 50,
  },
];
