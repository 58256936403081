import { Input, OnChanges, Output, SimpleChanges, ViewChild, EventEmitter, Directive } from '@angular/core';
import { MatTable, MatTableDataSource } from '@angular/material/table';
import { _log, _warn } from '@shared/aux_helper_environment';
import { _cloneDeep, _equal, _get } from '@shared/aux_helper_functions';
import { PriceDecisionFilterCompetitorMapTabCompetitorsTableAction } from 'app/prices/decisions/price-decisions/price-decision-tabs/store/price-decisions-tabs.store.actions';
import { BehaviorSubject } from 'rxjs';
import { CheckBoxData } from '../check-box/check-box-v2/model/checkBoxModel';
import { ColumnsType, PrismaTableTypePipes } from './prisma-table.model';

@Directive()
export class PrismaTableParentComponent<T> implements OnChanges {
  @ViewChild(MatTable, { static: true }) table: MatTable<T>;
  @Input() isLoading = false;
  @Input() noWrapTitle = false;
  @Input() stickyHeader = false; //Fixed Header

  /** utiliza el menor ancho posible por celda
   * @IMPORTANTE minWidthRows va a ser FALSE si groupedColumns = true
   */
  /*   @Input('minWidthRows') minWidthRows = false; */
  /** se expresa el valor exacto en px del ancho minimo que tienen las filas */
  /**
   * @fixedTableWidth width, ancho de la tabla. Para definir directamente cuanto quiero que tenga la tabla
   * @minCellWidth min-width de las columnas, defino el ancho minimo de cada columna, esto determina el ancho minimo
   * de la tabla haciendo la sumatoria de columnas activas, sirve tanto para tablas dinamicas como estaticas,
   * solo tiene en cuenta las que se estan mostrando
   */
  /** enviar en formato ngStyle para aplicar a tabla */
  @Input('styleClass') styleClass = null;
  @Input('responsive') responsive = { fixedTableWidth: null, minCellWidth: PRISMA_TABLE_MIN_CELL_WIDTH };
  /** setea altura de 544px y otros estilos */
  @Input('manualSelectionCss') manualSelectionCss = false;
  /** traspado configuracion de paginado */
  @Input('paginationConfig') paginationConfig = null;
  /** flag para habilitar la clase de pointer */
  @Input('clickeable') _clickeable = true;
  /** devuelve el objeto paginado de la tabla */
  @Output('page') page = new EventEmitter<any>();
  /**
   * Se envia una funcion con las condicion para mostrar o no la fila principal, el componente espera comparar datos de la fila
   */
  @Input('fxHideMainRow') conditionToHiddenMainRow: any = null;
  /** devuelve el elemento seleccionado para realizar operaciones con el en el componente padre */
  @Output('fxOpenRow') fxOpenRow = new EventEmitter<any>();
  /** devuelve la fila para realizar operaciones con el en el componente padre */
  @Output('fxMouseEnter') fxMouseEnter = new EventEmitter<any>();
  /** devuelve la fila para realizar operaciones con el en el componente padre */
  @Output('fxMouseLeave') fxMouseLeave = new EventEmitter<any>();
  /** devuelve checkbox seleccionado */
  @Output('updateCheckBoxData') updateCheckBoxData = new EventEmitter<CheckBoxData>();

  @Input() columns: string[];
  /**
   * @attrSubRows indico el nombre del path de que atributo hace referencia el listado de datos de sub rows para un element en dataSource
   */
  @Input() attrSubRows: string = null;
  @Input() addSpace = true;
  @Input() colSpanDropDown = PRISMA_TABLE_COL_SPAN_DROPDOWN;
  @Input() disabledDropDownValue = null;
  @Input() notFoundItemText = 'COMP.ITEMS_NO_FOUND.NO_RESULTS_FILTERS';

  /**
   * @dataSource obtengo la data para la tabla
   */
  @Input() dataSource: MatTableDataSource<T>;

  /**
   * @columnsConfig objeto con configuracion de grupos y columnas con sus estilos titulos ver interface ColumnsType
   */
  @Input() columnsConfig: Array<ColumnsType> = [];

  /**
   * @groupedColumns habilitar tabla dinamica - css, grupos de columnas
   * @IMPORTANTE SI TENEMOS SUBROWS SETEAR ESTE INPUT EN TRUE TMB, NO SOLO EN LA TABLA PADRE, PARA USAR CSS DE CELDAS DINAMICAS
   */
  @Input() groupedColumns = false;

  /**
   * @defaultCSS pisa el atributo class para imponer un css default en todas las columnas dinamicas.
   * Principalmente para dar el mismo ancho
   */
  @Input() defaultCSS = false;

  // obtengo datos de checks para la columna de seleccion cuando es necesario para una recursividad
  @Input() checkBoxData: CheckBoxData;

  @Input() fixedTableClass = false;

  @Input() disabledPagination = false;

  length = new BehaviorSubject<number>(0);
  pageSize;
  pageSizeOptions;
  pageIndex;
  rowWithHover = null;

  _colSpanDropDown = PRISMA_TABLE_COL_SPAN_DROPDOWN;
  _colSpanDropDownBase = PRISMA_TABLE_COL_SPAN_DROPDOWN;
  _minCellWidth = PRISMA_TABLE_MIN_CELL_WIDTH;
  _fixedTableWidth = '100%';

  /** @headerValues setea el objeto con todas los contenidos de columnas*/
  headerValues;
  /** @headerValuesGroup setea el objeto con todos los grupos de columnas y su contenido */
  headerValuesGroup;
  /** @displayedColumns listado de string con las columnas habilitadas*/
  displayedColumns;
  /** @displayedColumnsGroup listado de string con los grupos de columnas habilitadas */
  displayedColumnsGroup;

  /** @class="manualSelectionScroll" agregar en <prisma-table para hacerla scrolleable */

  prismaTableTypePipes = PrismaTableTypePipes;

  totalColSpan = 0;

  getColSpan = _getColSpan;

  onPageEvent($event) {
    this.page.emit($event);
  }

  openRow(element) {
    this.fxOpenRow.emit(element);
  }

  pageEventChange(paginationConfig) {}

  ngOnChanges(changes: SimpleChanges) {
    if (changes['responsive']) {
      if (this.responsive) {
        this.setResponsiveConfig();
      }
    }

    if (changes['dataSource']) {
      if (changes['colSpanDropDown']) {
        this._colSpanDropDown = this.colSpanDropDown ? this.colSpanDropDown : PRISMA_TABLE_COL_SPAN_DROPDOWN;
      }
      if (Array.isArray(this.dataSource)) {
        this.dataSource = new MatTableDataSource<any>(this.dataSource);
        _warn('el listado enviado en dataSource fue almacenado en dataSource.data y mapeado de array a MatTableDataSource');
      }
      if (!(this.dataSource instanceof MatTableDataSource)) _warn('dataSource debe tener data del tipo MatTableDataSource');
    }

    if (changes['paginationConfig']) {
      this.pageEventChange(this.paginationConfig);
    }
    if (changes['columnsConfig']) {
      let _columnsConfig = _cloneDeep(this.columnsConfig);

      // elimino o no columna select dependiendo de la configuracion de columnas
      this.manageCheckBoxColumn();

      // si envio el attr que contiene subrows configuro las columnas para activar la columna desplegable
      if (this.attrSubRows) {
        this.addCollapsedLogic();
        _columnsConfig = this.addCollapsedColumn(); // se hace en el subcomponent
      }

      _columnsConfig = _setColSpanToColumns(_columnsConfig);

      // configuracion de columnas con css default o no
      _columnsConfig = this.defaultCSS ? _setDefaultCSSDinamicColumns(_columnsConfig) : _columnsConfig;

      // definicion de data principal de la tabla, headerDef columnDef
      this.headerValues = _getDinamicColumnSelected(_columnsConfig, false);
      this.displayedColumns = _getColumns(_getDinamicColumnSelected(_columnsConfig, true));
      this.totalColSpan = 0;
      let activeColumns = _getDinamicColumnSelected(_columnsConfig, true);
      activeColumns.forEach(item => (this.totalColSpan += _calcColumnFlex(item.type)));

      // seteo columnas dinamicas
      if (this.groupedColumns) {
        let totalColumns = 0;
        activeColumns.forEach(item => (totalColumns += item.type.length));
        // si es null pasa a ser header por default o sea sin agrupado / sin titulo
        activeColumns = activeColumns.map(item => {
          return {
            ...item,
            name: !item.name ? PrismaTableDefaultHeader : item.name,
            totalChildrens: item.type.length,
            totalColumns: totalColumns,
            colFlex: _calcColumnFlex(item.type),
          };
        });
        this.displayedColumnsGroup = activeColumns.map(item => item.name);
        this.headerValuesGroup = activeColumns;
      }
      this.columnsConfig = _columnsConfig;
      _log('[Prisma Table - columnsConfig]', this.columnsConfig);
    }
  }
  /**
   * en el caso que tengamos sub rows agregamos el atributo para desplegarlo
   */
  addCollapsedLogic() {
    if (this.dataSource.data) {
      let rows = [];
      let data = _cloneDeep(this.dataSource.data);
      data.forEach(element =>
        rows.push({
          ...element,
          expanded: element?.expanded || false,
          disabledDropDown: this.disabledDropDownValue ? !element[this.disabledDropDownValue] : false,
        })
      );
      this.setDataStore(rows);
    }
  }
  /**
   * modificamos el modelo de columnConfig agregando como primer columna el desplegable
   */
  addCollapsedColumn() {
    if (this.columnsConfig.length > 0) {
      let _columnsConfig = this.columnsConfig.map((group, index) => {
        const hasCollapseColumn = index === 0 && this.determinateColumn(group.type, collapseColumnName);
        if (!hasCollapseColumn) {
          let _group = _cloneDeep(group);
          return this.addColumnAfterSelect(_group, collapseColumnConfig, collapseColumnName);
        } else {
          return group;
        }
      });
      return _columnsConfig;
    }
  }

  /**
   * Cuando el nombre de un grupo de columnas es null no tiene titulo en header
   */
  isNotDefault(group) {
    return group.name !== PrismaTableDefaultHeader;
  }

  /**
   * seteamos la data de la tabla
   */
  public setDataStore(rows) {
    this.dataSource.data = rows;
  }
  /**
   * seteamos la configuracion de columnas
   * @deprecated no se usa
   */
  public setColumnType(_columnTypes) {
    this.columnsConfig = _columnTypes;
  }

  /**
   * obtengo el atributo que tiene el listado de sub rows / sub filas
   */
  stateOfCollapseRow(element) {
    const _attrSubRow = _get(element, this.attrSubRows);
    return _attrSubRow && _attrSubRow.length > 0;
  }

  toggleExtand(element) {
    this.dataSource.data = this.dataSource.data.map(e => (_equal(e, element) ? { ...element, expanded: !element.expanded } : e));
  }

  setResponsiveConfig() {
    this._minCellWidth = this.responsive.minCellWidth ? this.responsive.minCellWidth : this._minCellWidth;
    this._fixedTableWidth = this.responsive.fixedTableWidth ? this.responsive.fixedTableWidth + 'px' : this._fixedTableWidth;
  }
  onMouseEnter(row) {
    this.rowWithHover = row;
    this.fxMouseEnter.emit(row);
  }
  onMouseLeave(row) {
    this.rowWithHover = null;
    this.fxMouseLeave.emit(row);
  }

  addColumnAfterSelect(list, columnConfig, columnName) {
    let restGroup = list.type;
    let newGroupType = [columnConfig, ...restGroup];
    const hasCheckColumn = this.determinateColumn(list.type, selectColumnName);
    // chequeo si tiene columna select
    if (hasCheckColumn) {
      restGroup = restGroup.filter(column => column.column !== selectColumnName);
      newGroupType = [selectColumnConfig, columnConfig, ...restGroup];
    }
    list.type = newGroupType;
    return list;
  }

  /** elimino columna select si esta deshabilitada */
  manageCheckBoxColumn() {
    this.columnsConfig = (this.columnsConfig || []).map((group, index) => {
      let restGroup = group.type;
      const hasCheckColumn = index === 0 && this.determinateColumn(group.type, selectColumnName, false);
      // chequeo si tiene columna select
      if (hasCheckColumn) {
        restGroup = restGroup.filter(column => column.column !== selectColumnName);
      }
      return { ...group, type: restGroup };
    });
  }

  // chequeo si tiene columna
  determinateColumn(list, columnNameToDetect, active = true) {
    return list.some(column =>
      active ? column.column === columnNameToDetect && column.isChecked && !column.hidden : column.column === columnNameToDetect
    );
  }

  _updateCheckBoxData(checkbox) {
    this.updateCheckBoxData.emit(checkbox);
  }
}

/**
 * seteo el mismo css para todoas las columnas dinamicas
 */
export const _setDefaultCSSDinamicColumns = (
  _columnsType,
  _defaultCSSDinamicColumns = 'fx-10 pr-8 _cellNoPad mat-header-cell'
): Array<ColumnsType> => {
  return _columnsType.map(group => {
    group.type.map(column => {
      return { ...column, class: _defaultCSSDinamicColumns };
    });
  });
};

/**
 * obtengo configuracion de columnas con todas las activas dinamicas (@requiredColumns true) o solo las dinamicas activas (@requiredColumns false)
 */
export const _getDinamicColumnSelected = (_columnsType, requiredColumns = false): Array<ColumnsType> => {
  const list = _columnsType.map(group => {
    let _group = _cloneDeep(group);

    _group.type = requiredColumns
      ? _group.type.filter(_column => _column.isChecked && !_column.hidden) // obtener para configuracion de columnas todas las columnas
      : _group.type.filter(_column => _column.isChecked && !_column.hidden && _column.isEditable); // ( _column.isEditable ) obtengo solo columnas dinamica y no obligatorias.
    return _group;
  });
  return list.filter(group => group.type.length > 0);
};

/**
 * obtengo el listado de columnas para la configuracion en la tabla como displayColumns
 */
export const _getColumns = (_dinamicColumnSelected): Array<String> => {
  return _dinamicColumnSelected.flatMap(group => group.type).map(item => item.column);
};

/**
 *
 * @param _column configuracion de la columna
 * @returns number escala de crecimiento de la columna
 */
export const _getColSpan = (_column): number => {
  return _column.colSpan ? _column.colSpan : PRISMA_TABLE_COL_SPAN;
};

export const _setColSpanToColumns = _columnsConfig => {
  return _columnsConfig.map(group => {
    return { ...group, type: _setColSpan(group.type) };
  });
};

export const _setColSpan = columns => {
  return columns.map(col => {
    return { ...col, colSpan: _getColSpan(col) };
  });
};

export const _calcColumnFlex = (columns): number => {
  let counter = 0;
  columns.forEach(col => (counter += _getColSpan(col)));
  return counter;
};

export const PRISMA_TABLE_COL_SPAN_DROPDOWN = 0.64;
export const PRISMA_TABLE_COL_SPAN_CHECKBOX = 0.64;
export const PRISMA_TABLE_COL_SPAN = 1;
export const PRISMA_TABLE_MIN_CELL_WIDTH = 100;
export const PrismaTableDefaultHeader = 'defaultHeader';

/**
 * configuracion default para la columna del desplegable
 */
export const collapseColumnName = 'collapseDetail';
export const collapseColumnConfig = {
  column: collapseColumnName,
  markOnNull: true,
  colSpan: PRISMA_TABLE_COL_SPAN_DROPDOWN,
  type: 'text',
  class: '',
  fxLayoutAlign: 'end center',
  headerTitle: '',
  isChecked: true,
  isEditable: false,
};

/**
 * configuracion default para la columna de checkbox
 */
export const selectColumnName = 'select';
export const selectColumnConfig = {
  column: selectColumnName,
  markOnNull: false,
  colSpan: PRISMA_TABLE_COL_SPAN_CHECKBOX,
  type: '',
  class: '',
  headerTitle: '',
  isChecked: true,
  isEditable: false,
};

/**
 * elimina la columna de este objeto de configuracion
 * @param columns configuracion de columnas a modificar
 * @param columnConfigToDrop columna a eliminar. Puede ser el nombre de identificacion o la configuracion completa obj
 * @returns new configTable
 */
export const dropSelectColumn = (columns, columnConfigToDrop) => {
  const columnName = typeof columnConfigToDrop === 'string' ? columnConfigToDrop : columnConfigToDrop.column;
  let selectIndex = columns.findIndex(c => c.column === columnName);
  if (selectIndex !== -1) {
    columns.splice(selectIndex, 1);
  }
  return columns;
};

/**
 * elimina la columna de todos los grupos de configuraciones
 * @param configTable configracion de tabla a modificar
 * @param columnConfig columna a eliminar. Puede ser el nombre de identificacion o la configuracion completa obj
 * @returns new configTable
 */
export const dropColumnOnAllGroups = (configTable, columnConfig = null) => {
  return configTable.map(columnGroup => {
    return { ...columnGroup, type: dropSelectColumn(columnGroup.type, columnConfig) };
  });
};
/**
 *
 * @param configTable configracion de tabla a modificar
 * @returns configTable sin columnas hidden
 */
export const dropHiddenColumns = (configTable, hiddenColumns = null) => {
  return configTable.map(columnGroup => {
    return {
      ...columnGroup,
      type: columnGroup.type.filter(x => (hiddenColumns ? !hiddenColumns.some(columnName => columnName === x.column) : !x.hidden)),
    };
  });
};

/**
 * para un solo grupo de columnas se agrega la columna deseada, si existe el param comparableColumnConfig, lo agrega unicamente si existe en el, para hacer un rollback
 * @param configTable configracion de tabla a modificar
 * @param columnConfig columna a agregar
 * @param comparableColumnConfig configuracion de tabla original para obtener datos
 * @param lastPosition agregar columna al final (visualmente se ve al fondo)
 * @returns new configTable
 */

export const addColumnAtTheSides = (configTable, columnConfig, comparableColumnConfig = null, lastPosition = false) => {
  let _config = columnConfig;
  /* utilizo esta configuracion para chequear si existe la column que quiero agregar a la otra configuracion. Si no existe aborto operacion */
  if (comparableColumnConfig) {
    const columnName = typeof columnConfig === 'string' ? columnConfig : columnConfig.column;
    const _columnConfig = comparableColumnConfig.find(c => c.column === columnName);
    if (_columnConfig === undefined) return configTable;
    _config = _columnConfig !== undefined ? _columnConfig : columnConfig;
  }
  lastPosition ? configTable.push(_config) : configTable.splice(0, 0, _config);
  return configTable;
};
/**
 * Agrega una columna en todos grupos de columnas, en el caso que el 3er parametro se utilice solo agrega las columnas en los grupos que figure la columna, cumple de funcion de rollback
 * @param configTable configracion de tabla a modificar
 * @param columnConfig columna a agregar
 * @param originalConfig configuracion de tabla original para obtener datos
 * @param lastPosition agregar columna al final (visualmente se ve al fondo)
 * @returns new configTable
 */
export const addColumnToAllGroups = (configTable, columnConfig, originalConfig = null, lastPosition = false) => {
  return configTable.map((columnGroup, index) => {
    return { ...columnGroup, type: addColumnAtTheSides(columnGroup.type, columnConfig, originalConfig[index].type, lastPosition) };
  });
};
