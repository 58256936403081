import { _log } from '@shared/aux_helper_environment';
import { _equal } from '@shared/aux_helper_functions';
import { _getUseUXV2 } from 'app/layout/components/global-settings-panel/global-settings-panel.component';
import { environment } from 'environments/environment';
import {
  CANCEL_PRISMA_TABLE_CONFIG,
  PrismaTableUserConfigureColumnComponent,
  RESET_PRISMA_TABLE_CONFIG,
} from './components/user-configure-columns/user-configure-columns.component';
export enum PrismaTableTypePipes {
  empty = '',
  text = 'text',
  number = 'number',

  int = 'int',
  maxTwoDigits = 'numberStuff',
  twoDigits = 'numberTwoDecimalPlaces',

  priceInt = 'priceInt',
  price = 'priceNumberStuff',
  priceTwoDigits = 'priceNumberTwoDecimalPlaces',

  percent = 'percent',

  function = 'function',

  customContent = 'customContent',
  spinnerWithCustomContent = 'spinnerWithCustomContent',
  icon = 'icon',
  actions = 'actions',
  color = 'color',

  time = 'time',
  date = 'date',
  dateV2 = 'dateV2',
  dateAlt = 'dateAlt',
  dateTime = 'dateTime',
}
export interface dinamicElementDataSourceStaticDataModel {
  column: string; // nombre de la columna con el que se configura, generalmente es el nombre que tiene en el atributo del modelo
  class: string; // definimos clases para la columna
  type: string; // se puede hacer un enum, es para configurar el uso de pipes como ser %
  colSpan?: number; // permite decidir cuantas columnas ocupa de ancho, por default es "1" ya que se calcula ese valor 1 por flex auto
  markOnNull: boolean; // habilita mostrar - si el dato en null
  fxLayoutAlign?: string; // define la alineacion del contenido de la columna usa las mismas definiciones que el fx comun
  headerTitle?: string; // define el titulo de la columna
  chips?: any; // listados de chips (element ) => [{tooltip?: string, value: dato a mostrar, click?: () => {}}]
  headerTitleLink?: string; //url para que el titulo tenga link
  headerToolTip?: string; // define el tooltip para el titulo de la columna / header
  cellToolTip?: string; // define el tooltip para la celda
  hidden?: boolean; // oculta la columna para no visualizarla por pantalla en ningun componente, actualmente aplica a componentes => column config y prisma table
  icon?: string; //agrega el nombre del icono a un type customContent
}

/** dinamicElementDataSourceModel
 * Contiene la data basica de configuracion y se agregan funciones para personalizar y procesar contenido.
 */
export interface dinamicElementDataSourceModel extends dinamicElementDataSourceStaticDataModel {
  typeFunc?: any; // permite enviar una funcion que es activada y usada con type: 'function'
  spinner?: any; // ingresar funcion para activar spinner en listado
  customContent?: any; // ingresar funcion contenido personalizado, en general se delega a una funcion para procesar el contenido
  customClassContent?: any; // ingresar funcion sirve para aplicar css en base a una logica vinculada al dato de la columna
  customStylesContent?: any; // ingresar funcion sirve para aplicar styles en formato ngStyle en base a una logica vinculada al dato de la columna
  backgroundColor?: any; //ingresar funcion sirve para aplicar color de background en celda
  customAction?: any; // ingresar funcion Realizar accion personalizada al clickear row column especifica
  customHeaderTitle?: any; // ingresar funcion para procesar titulo del header, sobreescribe headerTitle *no desarrollado*
  customHeaderToolTip?: any; // ingresar funcion para procesar tooltip del header, sobreescribe headerToolTip *no desarrollado*
  customCellTitle?: any; // ingresar funcion para procesar titulo de la celda, sobreescribe headerTitle *no desarrollado*
  customCellTitleToolTip?: any; // ingresar funcion para procesar tooltip de la celda, sobreescribe cellToolTip*no desarrollado*
}

export interface checkDinamicColumn extends dinamicElementDataSourceModel {
  // define si se ve o no una columna
  isChecked: boolean;
  // isEditable es parecido a decir si es dinamico,indica si es una columna dinamica y si se puede editar su estado de agregar quitar,
  // mapean el dataStore
  isEditable: boolean; // checkbox deshabilitado o no si se puede editar visibilidad de la columna
}

export interface ColumnsType {
  id: number;
  name: string; // nombre del grupo de columnas
  type: Array<checkDinamicColumn>; // listado de columnas con su configuracion
}

const _keyVer = environment.localStorageKeyVerPrefix || '_v3_';
const _keyVerTable = environment.localStorageKeyVerTablePrefix || '_v3_v1';
/*
 * almacenar en local storage un obj
 */
export const saveOnLocalStorage = (data, key: string) => {
  localStorage.setItem(_keyVer + _keyVerTable + key, JSON.stringify(data));
};

/*
 * obtener en local storage un obj
 */
export const getFromLocalStorage = (key: string): any => {
  const dataPersisted = localStorage.getItem(_keyVer + _keyVerTable + key);
  return dataPersisted ? JSON.parse(dataPersisted) : null;
};

/*
 * elimina un registro en local storage
 */
export const cleanIndividualLocalStorage = (key: string) => {
  localStorage.removeItem(_keyVer + _keyVerTable + key);
};

/*
 * Persistir configuracion de columnas en local storage
 */
export const columnsConfigToLocalStorage = (columnsConfig, keyOfLocalStorage, lvl = null) => {
  !lvl ? saveOnLocalStorage(columnsConfig, keyOfLocalStorage) : saveOnLocalStorage(columnsConfig[lvl].type, keyOfLocalStorage + lvl);
};

/* parseColumnToAddFunctions
 * solo se graban los atributos con datos estaticos por lo que se utiliza este metodo para agregarle la configuracion de funciones definidas en el controlador
 * devuelve configuracion completa de la columna
 */
const parseColumnToAddFunctions = (columnGroup, currentColumnGroup): Array<checkDinamicColumn> => {
  let _columnGroup = columnGroup;
  if (currentColumnGroup !== undefined) {
    _columnGroup = columnGroup.map(ls => {
      const columnConfig = currentColumnGroup.find(x => x.column === ls.column);
      let {
        column,
        class: any,
        type,
        colSpan,
        markOnNull,
        fxLayoutAlign,
        headerTitle,
        headerToolTip,
        cellToolTip,
        isChecked,
        isEditable,
        ...functionalConfig
      } = columnConfig;
      return columnConfig !== undefined ? { ...ls, ...functionalConfig } : ls;
    });
  }
  return _columnGroup;
};

/*
 * Obtener la configuracion completa de la tabla, incluyendo si tiene diferentes grupos
 */
const getColumnsConfigOnLocalStorage = (columnsConfig: Array<ColumnsType>, key: string): Array<ColumnsType> => {
  const dataPersistedObj = getFromLocalStorage(key) as Array<ColumnsType>;
  if (!dataPersistedObj) return null;
  return dataPersistedObj.map(columnGroup => {
    const currentColumnGroup = columnsConfig.find(c => c.id === columnGroup.id);
    return { ...columnGroup, type: parseColumnToAddFunctions(columnGroup.type, currentColumnGroup.type) };
  });
};

/*
 * Obtener datos de un unico grupo de columnas
 */
const getColumnsConfigOnSingleGroupLocalStorage = (columnsConfig: Array<checkDinamicColumn>, key: string): Array<checkDinamicColumn> => {
  const dataPersistedObj = getFromLocalStorage(key) as Array<checkDinamicColumn>;
  if (!dataPersistedObj) return null;
  return parseColumnToAddFunctions(dataPersistedObj, columnsConfig);
};

/*
 * Obtener la configuracion de columnas de local storage
 */
export const localStorageToColumnsConfig = (
  columnsConfig,
  keyOfLocalStorage,
  lvl = null
): Array<ColumnsType> | Array<checkDinamicColumn> => {
  let useStorage = _getUseUXV2();

  if (!useStorage) return null;

  return !lvl
    ? getColumnsConfigOnLocalStorage(columnsConfig, keyOfLocalStorage)
    : getColumnsConfigOnSingleGroupLocalStorage(columnsConfig[lvl].type, keyOfLocalStorage + lvl);
};

export const createDialogConfigTable = (dialog, columnsType, hiddenColumns = null) => {
  return dialog.open(PrismaTableUserConfigureColumnComponent, {
    width: '550px',
    data: { config: columnsType, disabledDragsActive: false, hiddenColumns },
  });
};

export const actionConfigTable = (data, columnsType, initColumn, localStorageKEY) => {
  if (!_equal(data, columnsType) && data !== RESET_PRISMA_TABLE_CONFIG && data !== CANCEL_PRISMA_TABLE_CONFIG) {
    columnsConfigToLocalStorage(data, localStorageKEY);
    columnsType = data;
  }
  if (data === RESET_PRISMA_TABLE_CONFIG) {
    cleanIndividualLocalStorage(localStorageKEY);
    columnsType = initColumn;
  }
  return columnsType;
};

export const actionConfigToolBarConfigTable = translate => {
  return {
    type: 'config-table-columns',
    name: translate.instant('COMP.PRISMA_TABLE.CONFIG_COLUMNS'),
    disabled: false,
    icon: 'view_column',
    tooltip: translate.instant('COMP.PRISMA_TABLE.CONFIG_COLUMNS'),
    iconClass: 'grey-icon',
    children: [],
  };
};
